.navbar {
  background: rgb(30, 30, 30);
  height: 5rem;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
}

.logo {
  font-family: 'Apple Chancery', cursive;
  color: white;
  cursor: pointer;
  margin-right: auto;
  transition: transform 0.3s ease-in-out;
}

.logo:hover{
  transform: scale(1.1);

}

.logoDiv{
  font-family: 'Apple Chancery';
   color: 'white';
   font-size: 2.25rem;
}
.logoDiv:hover{
  color: gold;
}
.desktopMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.desktopMenuListItem {
  margin-right: 5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.desktopMenuListItem:hover {
  color: gold;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid gold;
}

.mobMenu {
  display: none;
  object-fit: cover;
  height: 1.8rem;
  cursor: pointer;
}

.navMenu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(40, 40, 40);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.closeMenu {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  cursor: pointer;
}

.listItem {
  color: white;
  padding: 1rem;
  margin: 1rem 0;
  background: rgb(30, 30, 30);
  width: 80%;
  text-align: center;
  border-radius: 0.5rem;
}

@media screen and (max-width: 720px) {
  .mobMenu {
    display: flex;
    cursor: pointer;
  }

  .desktopMenu {
    display: none;
  }
}
