#experience {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;

}

.expTitle{

    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
    margin-top: -3.5rem;
}
.expDesc {

    font-weight: 200;
    font-size: 1rem;
    max-width: 50rem;
    padding: 0 2rem;
}

.expBars {

    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.expBar {

    display: flex;
    color: white;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: .5rem;
    background: rgb(50, 50, 50);
    transition: transform 0.4s ease-in-out;
}
.expBar:hover {
    transform: scale(1.02);
  }

.expBarImg {

    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    cursor: pointer;
    display: flex;
    

}
.expBarImgSport {

    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    display: flex;
    

}

.expBarText >p {

    font-size: .95rem;
    font-weight: 200;
    
}
hr.solid {
    width: 100vw;
  }
@media screen and (max-width: 720px) {
    .expBarText >p {
        font-size: 2vw;
    }
}

@media screen and (max-width: 480px) {
    .expBarText >p {
        font-size: 3vw;
    }
    .expBarImg{
        height: 2.25rem;
        width: 2.25rem;
    }
    .expBarText >h2 {
        font-size: 5vw;
    }
} 