
#contactPage{
    margin: 0 auto;
    /* min-height: calc(100vh - 4rem); */
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    /* height: 150vh; */
}

.contactTitle{
    /* margin: 1rem 0; */
    font-size: 3rem;
}

.contactDescription{
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 600rem;
}

.name, .email, .msg{
    margin: .5rem;
    padding: .5rem;
    width: 100%;
    border: none;
    border-radius: .5rem;
    font-size: medium;
    font-weight: 300;
    max-width: 40rem;
    color: white;
    border:none;
    background: rgb(40, 40, 40);
}

.submitBtn{
    background: white;
    color:black;
    border: none;
    padding: .75rem 3.5rem;
    border-radius: 2rem;
    margin: 2rem;
    transition: transform 0.3s ease-in-out; 
    font-family: 'Arial';
    font-weight: bold;
}
.submitBtn:hover{
    transform: scale(1.1);
}


.links {
    display: flex;
    flex-wrap: wrap;
}

  .link:hover {
    transform: scale(1.1);
  }
.link{
    object-fit: cover;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 0.75rem;
    cursor: pointer;transition: transform 0.3s ease-in-out;
}

.submitBtn{

    cursor: pointer;

}
.solid{
    color: white;
}