#works{
    margin: 0 auto;
    min-height: calc(100vh -4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    background: rgb(30, 30, 30);;
    
}

.gitHubGold {
    transition: transform 0.3s ease-in-out; 
    
    color:gold;
}
.gitHubGold:hover{
    transform: scale(1.15);
}

.worksImgContainer{

    position: relative;
    transition: transform 0.3s ease-in-out; 
}
.worksImgContainer:hover{
    transform: scale(1.05);
}

.textTitle{
    font-size: 1.5rem;
    font-weight: 500;
    margin: .5rem 0;

}
.text-block {
    position: absolute;
    bottom: 130px;
    /* right: 50px; */
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: .8rem;
  }
  .text-block2 {
    position: absolute;
    bottom: 150px;
    /* right: 50px; */
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: .8rem;
  }
.worksTitle{
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDesc{

    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;
}



.worksImgs{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;
}

.worksImg{
    display: inline-block;
    object-fit: fill;
    height: 364px;
    margin: 0.5rem;
    cursor: pointer;
    width: 320px; 
    border-radius: 2%;
    opacity: .25;
    /* height: auto; */
}



.image-link{
    display: inline-block;
    

}

.workBtn {
    margin: 3rem 0;
    padding: .5rem 2.5rem;
    background: white;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out; 

}

.workBtn:hover{
    transform: scale(1.05);
}

.solid {
    margin: 3rem;
    width: 117%;
    color: white;
    border: 1px solid white; 
  }
  hr.solid {
    width: 70vw;
  }

@media screen and (max-wdith: 585px){

    .worksImg{
        height: 48vw;
    }
}